<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
		<mask
			id="mask0_1343_3466"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="25"
		>
			<rect y="0.5" width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_1343_3466)">
			<path
				d="M18.35 10.6L16.95 9.15L19.1 7.05L20.5 8.45L18.35 10.6ZM2 20.5V18.5H22V20.5H2ZM11 7.5V4.5H13V7.5H11ZM5.65 10.55L3.55 8.4L4.95 7L7.1 9.15L5.65 10.55ZM7.425 14.5H16.575C16.1917 13.6 15.5917 12.875 14.775 12.325C13.9583 11.775 13.0333 11.5 12 11.5C10.9667 11.5 10.0417 11.775 9.225 12.325C8.40833 12.875 7.80833 13.6 7.425 14.5ZM5 16.5C5 14.55 5.67917 12.8958 7.0375 11.5375C8.39583 10.1792 10.05 9.5 12 9.5C13.95 9.5 15.6042 10.1792 16.9625 11.5375C18.3208 12.8958 19 14.55 19 16.5H5Z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
